<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue';
import { currency } from '@/utils/currency';
import storeIconUrl from '@/assets/icons/store.svg';
import menuUserIconUrl from '@/assets/icons/menu-user.svg';
import bookingsIconUrl from '@/assets/icons/bookings.svg';
import profileMenuItem from './profile-menu-item.vue';
import { partnerAccountsApi } from '@/api/partner-accounts';

const user = inject<User>('user');

const activeReferralCampaign = inject<boolean>('activeReferralCampaign');

const partnerEarnings = ref<PartnerEarnings>();
const loading = ref(false);

const MENUS = [
  {
    name: 'Mi tienda',
    icon: storeIconUrl,
    subitems: [
      { name: 'Solicitudes y reservas', path: '/perfil/solicitudes_reservas/socio' },
      { name: 'Mis productos', path: '/perfil/productos' },
      { name: 'Ver mi tienda', path: `/socio/${user?.partnerAccountId}` },
    ],
    partnerOnly: true,
  },
  {
    name: 'Mis arriendos',
    icon: bookingsIconUrl,
    subitems: [
      { name: 'Solicitudes y reservas', path: '/perfil/solicitudes_reservas/cliente' },
      { name: 'Mi historial', path: '' },
    ],
  },
  {
    name: 'Mis datos',
    icon: menuUserIconUrl,
    subitems: [
      { name: 'Mi cuenta', path: '/perfil/cuenta' },
      { name: 'Mis direcciones', path: '/perfil/direcciones' },
      { name: 'Mis datos bancarios', path: '/perfil/datos_bancarios', partnerOnly: true },
    ],
  },
];

const filteredMenus = computed(() => {
  let finalMenus = MENUS;
  if (!user?.partnerAccountId) {
    finalMenus = finalMenus.filter((menu) => !menu.partnerOnly);
  }

  return finalMenus;
});

function shareReferralLink() {
  const url = `https://${window.location.hostname}/usuarios/registro?refcode=${user?.referralCode?.code}`;
  if (navigator.share) {
    navigator.share({
      title: 'Únete a Zirkular! Conoce la nueva forma de arrendar',
      url,
    });
  } else {
    navigator.clipboard.writeText(url);
  }
}
function copyReferralCode() {
  if (user?.referralCode) {
    navigator.clipboard.writeText(user?.referralCode.code);
  }
}

const showReferralDetails = ref(false);

function toggleReferralDetails() {
  showReferralDetails.value = !showReferralDetails.value;
}

onMounted(() => {
  if (user?.partnerAccountId) {
    loading.value = true;
    partnerAccountsApi.earnings(user?.partnerAccountId)
      .then(response => {
        partnerEarnings.value = response;
        loading.value = false;
      });
  }
});
</script>

<template>
  <div class="flex flex-col divide-y divide-z-gray-100">
    <div
      v-if="user?.partnerAccountId"
      class="grid grid-cols-2 gap-2 px-6 py-4"
    >
      <div class="rounded-lg border border-z-gray-100 bg-gray-50 px-4 py-3 text-z-gray-800">
        <p class="text-xs">
          Total ganado
        </p>
        <img
          v-if="loading"
          src="@/assets/icons/loading.svg"
          class="w-5 animate-spin-slow"
        >
        <p
          v-else
          class="mt-1 text-lg font-semibold"
        >
          {{ currency(partnerEarnings?.totalEarnings ?? 0) }}
        </p>
      </div>
      <div class="rounded-lg border border-z-gray-100 bg-gray-50 px-4 py-3 text-z-gray-800">
        <p class="text-xs">
          Ganancia del mes
        </p>
        <img
          v-if="loading"
          src="@/assets/icons/loading.svg"
          class="w-5 animate-spin-slow"
        >
        <p
          v-else
          class="mt-1 text-lg font-semibold"
        >
          {{ currency(partnerEarnings?.monthlyEarnings ?? 0) }}
        </p>
      </div>
    </div>
    <div
      v-if="activeReferralCampaign"
      class="flex flex-col  px-6 py-4"
    >
      <template v-if="user?.referralCode">
        <div class="flex w-full items-center justify-between">
          <div class="flex flex-col">
            <p class="text-sm text-z-gray-800">
              Tu código de referido es:
            </p>
            <button
              class="mt-1 w-fit font-mono font-semibold tracking-wide text-z-gray-900"
              @click="copyReferralCode"
            >
              {{ user?.referralCode.code }}
            </button>
          </div>
          <div class="flex">
            <button
              class="flex items-center space-x-2 rounded border bg-z-gray-50 px-2 py-1"
              @click="shareReferralLink"
            >
              <img
                class=""
                src="@/assets/icons/share.svg"
              >
              <p
                class="text-sm text-z-gray-900"
              >
                Compartir
              </p>
            </button>
            <button
              class="pl-2"
              @click="toggleReferralDetails"
            >
              <img
                src="@/assets/icons/chevron-down.svg"
                :class="{ 'rotate-180': showReferralDetails}"
              >
            </button>
          </div>
        </div>
        <div
          v-if="showReferralDetails"
          class="mt-2 flex flex-col space-y-2 rounded-lg border border-z-gray-100 bg-z-gray-50 px-3 py-2 text-sm text-z-gray-900"
        >
          <p>Por cada persona que se registre con tu código y suba 3 productos, ambos obtendrán <span class="font-medium">$3.000</span> de regalo.</p>
          <a
            class="text-z-turquoise-700 underline underline-offset-2"
            href="/legal/referidos"
          >
            Ver términos y condiciones
          </a>
        </div>
      </template>
      <template v-else>
        <div class="flex w-full flex-col">
          <button
            class="flex items-center justify-between"
            @click="toggleReferralDetails"
          >
            <p class="text-left font-medium leading-5 text-z-gray-800">
              ¡Obtén tu código de referido y gana!
            </p>
            <img
              src="@/assets/icons/chevron-down.svg"
              :class="{ 'rotate-180': showReferralDetails}"
            >
          </button>
          <div
            v-if="showReferralDetails"
            class="my-2 flex flex-col space-y-2 rounded-lg border border-z-gray-100 bg-z-gray-50 px-3 py-2 text-sm text-z-gray-900"
          >
            <p>Por cada persona que se registre con tu código y suba 3 productos, ambos obtendrán <span class="font-medium">$3.000</span> de regalo.</p>
            <a
              class="text-z-turquoise-700 underline underline-offset-2"
              href="/legal/referidos"
            >
              Ver términos y condiciones
            </a>
          </div>
          <p class="text-xs text-z-gray-800">
            Completa las siguientes tareas para obtener tu código
            <span v-if="user?.hasCodeRedeem">y ganar tus $3.000 iniciales</span>
          </p>
          <p class="mt-2 text-z-gray-900">
            Productos subidos:
          </p>
          <base-progress-bar
            :total="3"
            :value="user?.productsCount"
            class="mt-1"
          />
          <div class="mt-2 flex justify-between">
            <div class="flex size-6 items-center justify-center rounded-full bg-z-gray-200 font-medium">
              0
            </div>
            <div class="flex size-6 items-center justify-center rounded-full bg-z-gray-200 font-medium">
              3
            </div>
          </div>
        </div>
      </template>
    </div>
    <profile-menu-item
      v-for="menuItem, index in filteredMenus"
      :key="index"
      :menu-item="menuItem"
    />
    <div
      class="flex flex-col"
    >
      <a
        class="flex justify-between px-6 py-5"
        href="/soporte"
      >
        <div class="flex space-x-2">
          <img
            src="@/assets/icons/help-alt.svg"
            class="w-6"
          >
          <p class="text-z-gray-900">
            Chats de soporte
          </p>
        </div>
      </a>
    </div>
    <div
      class="flex flex-col"
    >
      <a
        class="flex justify-between px-6 py-5"
        href="/usuarios/cerrar_sesion"
      >
        <div class="flex space-x-2">
          <img
            src="@/assets/icons/logout.svg"
            class="w-6"
          >
          <p class="text-z-gray-900">
            Cerrar sesión
          </p>
        </div>
      </a>
    </div>
  </div>
</template>
