import { api } from './index';

export interface ProductFormData extends Product {
  addressId: number
  productRequestId?: number
  categoryId: number
  subcategoryId: number
  pictures: File[]
  shippingMethodIds: string[]
}

export const productsApi = {
  index(latitude:number, longitude:number) {
    const path = `/api/internal/products?latitude=${latitude}&longitude=${longitude}`;

    return api({
      method: 'get',
      url: path,
    }).then(response => response.data.products as Product[]);
  },

  highlighted(latitude?: number, longitude?: number) {
    const coordParams = latitude && longitude ? `?latitude=${latitude}&longitude=${longitude}` : '';
    const path = `/api/internal/products/highlighted${coordParams}`;

    return api({
      method: 'get',
      url: path,
    }).then(response => response.data.products as Product[]);
  },

  search(searchTerms:string, latitude?:number, longitude?:number) {
    const coordParams = latitude && longitude ? `&latitude=${latitude}&longitude=${longitude}` : '';
    const path = `/api/internal/products/search?q=${searchTerms}${coordParams}`;

    return api({
      method: 'get',
      url: path,
    }).then(response => response.data.products as Product[]);
  },

  show(id:number) {
    const path = `/api/internal/products/${id}`;

    return api({
      method: 'get',
      url: path,
    });
  },
  create(productData:Partial<ProductFormData>, pictures:File[]) {
    const path = '/api/internal/products';

    return api({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: path,
      data: { ...productData, pictures },
    });
  },
  update(
    productId:number,
    productData:Partial<ProductFormData>,
    picturesToDelete?:number[],
    newPictures?:File[],
  ) {
    const path = `/api/internal/products/${productId}`;

    return api({
      method: 'patch',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: path,
      data: { ...productData, picturesToDelete, newPictures },
    });
  },
  delete(id: number) {
    const path = `/api/internal/products/${id}`;

    return api({
      method: 'delete',
      url: path,
    });
  },
};
