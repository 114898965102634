<script setup lang="ts">
import { inject } from 'vue';

interface Props {
  isOpen: boolean
}

defineProps<Props>();

const user = inject<User>('user');
</script>
<template>
  <base-modal
    :open="isOpen"
  >
    <div class="flex flex-col">
      <img
        src="@/assets/icons/check-circle.svg"
        class="w-20 self-center"
      >
      <p class="mt-4 text-center font-medium text-z-gray-900">
        {{ $t('newProductForm.successModal.title') }}
      </p>
      <p class="mt-6 text-center text-z-gray-800">
        {{ $t('newProductForm.successModal.description') }}
      </p>
      <base-tip
        title=""
        class="mt-6"
      >
        <template #icon>
          <img
            src="@/assets/icons/information-circle.svg"
            class="h-6 w-6"
          >
        </template>
        {{ $t('newProductForm.successModal.tip') }}
      </base-tip>
      <template v-if="!user?.hasBankAccount || !user?.verified">
        <base-tip
          title=""
          class="mt-6"
        >
          Te recomendamos <span class="font-semibold">verificar tu cuenta</span> para recibir tus futuros ingresos por arriendos o campañas de referido.
        </base-tip>
        <base-button
          href="/perfil/verificar?from=new_product_form&redirect_to=/"
          class="mt-4"
        >
          Verificar cuenta
          <img
            src="@/assets/icons/arrow-small-right.svg"
            class="ml-2"
          >
        </base-button>
      </template>
      <base-button
        v-else
        href="/"
        class="mt-4"
      >
        Aceptar
      </base-button>
    </div>
  </base-modal>
</template>
